import { styled } from '@sparky/stitches';

export const VideoRatio = styled('div', {
  position: 'relative',
  paddingTop: '56.25%',
});

export const StyledFacadeImage = styled('img', {
  cursor: 'pointer',
  height: '100%',
  left: 0,
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  width: '100%',
});

export const StyledFacadeGradient = styled('div', {
  // Copy-paste of youtube's gradient overlay
  background:
    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==) repeat-x',
  height: '100px',
  left: 0,
  position: 'absolute',
  pointerEvents: 'none',
  top: 0,
  width: '100%',
  zIndex: 1,
});

export const StyledFacadeTitle = styled('a', {
  color: '#eee',
  fontSize: '1.8rem',
  position: 'absolute',
  left: 0,
  margin: '8px 0 0 8px',
  overflow: 'hidden',
  padding: '10px',
  textDecoration: 'none',
  textShadow: '0 0 2px rgba(0,0,0,.65)',
  textOverflow: 'ellipsis',
  top: 0,
  whiteSpace: 'nowrap',
  width: 'calc(100% - 8px)',
  zIndex: 1,

  '&:hover': {
    color: '#fff',
  },
});

export const StyledPlayButton = styled('svg', {
  left: '50%',
  pointerEvents: 'none',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '68px',
});

export const StyledIframe = styled('iframe', {
  border: 'none',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
});
