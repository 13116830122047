interface Devices {
  isAndroid: boolean;
  isIOS: boolean;
}

/**
 * The `useDevice` hook inspects the [user agent Navigator](https://developer.mozilla.org/en-US/docs/Web/API/Navigator) to
 * determine the type of device the user is visiting from.
 * ```jsx
 * import { useDevice } from '@sparky/hooks';
 *
 * const MyComponent = () => {
 *   const { isIOS, isAndroid } = useDevice();
 *   return isAndroid ? <AndroidIcon /> : isIos ? <IOSIcon /> : <FallBack />;
 * };
 * ```
 * @returns {Devices} An object containing the booleans `isAndroid` and `isIOS`.
 */
export const useDevice = (): Devices => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isAndroid = userAgent.match(/Android/i) !== null;
  const isIOS = userAgent.match(/(iPhone|iPad|iPod)/i) !== null;

  return {
    isIOS,
    isAndroid,
  };
};
